<template>
  <v-card id="channel-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <v-btn
          color="primary"
          class="me-3"
          @click="editInterviewQuestion(defaultItem)"
        >
          <span>Interview-Frage erstellen</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filter.searchString"
          dense
          outlined
          hide-details
          placeholder="Suchwort"
          class="channel-list-search me-3"
        ></v-text-field>
      </div>
      <div class="d-flex align-center pb-5">
        <v-select
          v-model="filter.angabeAktiv"
          label="Aktiv"
          outlined
          class="channel-list-search me-3"
          dense
          hide-details
          :items="filterActiveFlagOptions"
          item-text="key"
          item-value="value"
          :value-comparator="(a, b) => Math.round(a) == Math.round(b)"
        ></v-select>
      </div>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="listInterviewQuestions"
      :options.sync="options"
      :server-items-length="totalAmountList"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :search="filter.searchString"
      class="text-no-wrap"
      no-data-text="Keine Einträge vorhanden"
      no-results-text="Ihre Suche ergab keinen Treffer"
      :footer-props="{
        itemsPerPageText: 'Anzahl pro Seite',
        itemsPerPageAllText: 'Alle',
        pageText: '{0}-{1} von {2}',
        itemsPerPageOptions: [5,10,25,50,-1]
      }"
    >
      <template
        v-slot:top
      >
        <v-dialog
          v-model="dialogDelete"
          width="700px"
        >
          <v-card>
            <v-card-text
              class="text-center"
            >
              Möchten Sie <strong>&nbsp;{{ deleteItem.titel }}&nbsp;</strong> wirklich löschen?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="deleteInterviewQuestionConfirm"
              >
                Löschen
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="closeDelete"
              >
                Abbrechen
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogEdit"
          width="700px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="8"
                  >
                    <v-text-field
                      v-model="editItem.kennung"
                      label="Kennung"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="4"
                    sm="4"
                  >
                    <v-select
                      v-model="editItem.angabeAktiv"
                      label="Aktiv"
                      outlined
                      dense
                      hide-details="auto"
                      :items="[{text:'Ja',value:'1'},{text:'Nein',value:'0'}]"
                      item-text="text"
                      item-value="value"
                      class="invoice-list-actions "
                      :value-comparator="(a, b) => Math.round(a) == Math.round(b)"
                    ></v-select>
                  </v-col>

                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      v-model="editItem.frage"
                      label="Frage"
                      auto-grow
                      outlined
                      dense
                      hide-details="auto"
                      rows="6"
                      row-height="15"
                    ></v-textarea>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-select
                      v-model="editItem.typ"
                      label="Typ"
                      outlined
                      dense
                      hide-details="auto"
                      :items="[{value:'input'},{value:'textarea'},{value:'checkbox'},{value:'radio'},{value:'select'}]"
                      item-text="value"
                      item-value="value"
                      class="invoice-list-actions "
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="['checkbox','radio','select'].includes(editItem.typ)"
                    cols="12"
                  >


                    <v-row v-for="(option,optionKey) in editItem.auswahlOptionenJson">

                      <v-col
                        cols="5"
                      >
                        <v-text-field
                          v-model="option.label"
                          label="Anzeigetext"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="5"
                      >
                        <v-text-field
                          v-model="option.value"
                          label="Wert (Datenbank)"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="2"
                      >
                        <v-icon
                          small
                          class="page__grab-icon"
                          style="cursor:pointer"
                          title="Löschen"
                          @click="removeAuswahlOption(optionKey)"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </v-col>

                    </v-row>

                    <v-col
                      cols="12"
                    >
                      <v-icon
                        small
                        class="page__grab-icon"
                        style="cursor:pointer"
                        title="Hinzufügen"
                        @click="addNewAuswahlOption"
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-col>







<!--                    <v-textarea
                      v-model="editItem.auswahlOptionenJson"
                      label="Auswahl-Optionen"
                      auto-grow
                      outlined
                      dense
                      hide-details="auto"
                      rows="6"
                      row-height="30"
                    ></v-textarea>-->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :disabled="editValidationError"
                @click="editInterviewQuestionConfirm()"
              >
                Speichern
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                @click="closeEdit()"
              >
                Abbrechen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #[`item.idThInterviewFrage`]="{item}">
        #{{ item.idThInterviewFrage }}
      </template>
      <template #[`item.actions`]="{item}">

        <v-tooltip bottom v-if="item.idThHeadhunter > 0">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              @click="editInterviewQuestion(item)"
              v-on="on"
            >
              <v-icon size="18">
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>


        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              @click="replicateInterviewQuestion(item)"
              v-on="on"
            >
              <v-icon size="18">
                {{ icons.mdiContentCopy }}
              </v-icon>
            </v-btn>
          </template>
          <span>Replizieren</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.idThHeadhunter > 0">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="deleteInterviewQuestion( item )"
            >
              <v-icon size="18">
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Löschen</span>
        </v-tooltip>

      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPlus,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiFilePdf,
  mdiContentCopy,
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import { avatarText } from '@core/utils/filter'
import router from '@/router'

export default {
  name: 'channel-list',
  components: {

  },
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    return {
      vm,
      router
    }
  },
  data() {
    return {
      avatarText:avatarText,
      actions: ['Delete', 'Edit'],
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiFilePdf,
        mdiContentCopy,
      },
      filter: { searchString: '', angabeAktiv: 'all',datumVon:'',datumBis:''},
      loading: false,
      totalAmountList: 0,
      filterActiveFlagOptions : [
        {value:"all", key:"Alle"},
        {value:"1", key:"Ja"},
        {value:"0", key:"Nein"},
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idThInterviewFrage'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#',
          align: 'start',
          value: 'idThInterviewFrage',
        },
        {text: 'Kennung', value: 'kennung'},
        {text: 'Frage', value: 'frage'},
        {text: 'Typ', value: 'typ'},
        {text: 'Aktiv', value: 'angabeAktiv'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listInterviewQuestions: [],
      dialogDelete: false,
      dialogEdit: false,
      deleteItem: {
        idThInterviewFrage: 0,
        kennung: '',
        angabeAktiv: '',
        frage: '',
        auswahlOptionenJson: [],
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idThInterviewFrage: 0,
        kennung: '',
        angabeAktiv: '',
        frage: '',
        auswahlOptionenJson: [],
      },
      defaultItem: {
        idThInterviewFrage: 0,
        kennung: '',
        angabeAktiv: '1',
        frage: '',
        auswahlOptionenJson: [],
      },
      editValidationError : true,
    }
  },
  mounted() {
    this.fetchInterviewQuestions()
  },
  watch: {
    options: {
      handler() {
        this.fetchInterviewQuestions()
      },
    },
    filter: {
      handler() {
        this.fetchInterviewQuestions()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Interview-Frage hinzufügen' : 'Interview-Frage bearbeiten'
    },
    getBirthdayText() {
      return text => { return text ? `(${text})` : ''}
    },
  },
  methods: {
    removeAuswahlOption(key){
      this.editItem.auswahlOptionenJson = this.editItem.auswahlOptionenJson.filter ((x,i) => i != key)
    },
    addNewAuswahlOption(){
      console.log('defaultItem',this.defaultItem)
      this.editItem.auswahlOptionenJson.push({label:"",value:""});
    },
    fetchInterviewQuestions() {
      this.loading = true
      axios.post('api/interviewFrage/', {
        aktion: 'showOverviewInterviewQuestions',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listInterviewQuestions = response.data.interviewQuestions || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteInterviewQuestion (item) {
      this.dialogDelete = true
      this.deleteItem = item
    },
    deleteInterviewQuestionConfirm () {
      axios.post('api/interviewFrage/', {
        aktion: 'deleteInterviewQuestion',
        inputData :{idThInterviewFrage: this.deleteItem.idThInterviewFrage},
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchInterviewQuestions()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
    editInterviewQuestion (item) {
      this.editValidationError = false
      this.dialogEdit = true
      if(item) {
        this.editIndex = this.listInterviewQuestions.indexOf(item)
        this.editItem = JSON.parse(JSON.stringify(item))
      }
    },
    nameRules (v) {
      this.editValidationError = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if((v || '').length < 2) {
        return 'Der Name muss mindetens 2 Zeichen besitzen.';
      }
      else if((v || '').length > 255) {
        return 'Der Name kann maximal 255 Zeichen lang sein.';
      }

      this.editValidationError = false
      return true
    },
    editInterviewQuestionConfirm () {
      axios.post('api/interviewFrage/', {
        aktion: this.editIndex === -1 ? 'createInterviewQuestionCheck' : 'editInterviewQuestionCheck',
        inputData: this.editItem,
      })
        .then((response) => {
            if (response.data.valid === true) {
              this.vm.$toastr.s(response.data.message.title, response.data.message.text);
              this.fetchInterviewQuestions()
            }
            else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
          this.closeEdit()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    replicateInterviewQuestion(item){
      axios.post('api/interviewFrage/', {
        aktion: 'replicateInterviewQuestionCheck',
        inputData: item
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.vm.$toastr.s(response.data.message.title, response.data.message.text);
            this.fetchInterviewQuestions()
          }
          else {
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          this.closeEdit()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    closeEdit () {
      this.$nextTick(() => {
        this.dialogEdit = false
        this.editIndex = -1
        this.editItem = this.defaultItem
      })
    },
    updateFilter (key,value) {
      this.$nextTick(() => {
        this.filter[key] = value;
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
#channel-list {
  .channel-list-search {
    max-width: 10.625rem;
  }
  .channel-list-angabeAktiv {
    max-width: 11.3rem;
  }
  .channel-list-angabeArchiviert {
    max-width: 11.3rem;
  }
}
</style>
